<template>
         <div class="row">
        <div class="col-sm-12">
          <div class="card card-statistics">
            <div class="row no-gutters">
              <div class="col-xxl-3 col-lg-6">
                <div class="p-20 border-lg-right border-bottom border-xxl-bottom-0">
                  
                 <h4 class="text-muted">{{title}} Summary</h4>
                </div>
              </div>
              <div class="col-xxl-3 col-lg-6">
                <div class="p-20 border-xxl-right border-bottom border-xxl-bottom-0">
                  <div class="d-flex m-b-10">
                    <p class="mb-0 font-regular text-muted font-weight-bold">Today's Order</p>
                    <a class="mb-0 ml-auto font-weight-bold">
                      <i class="ti ti-more-alt"></i>
                    </a>
                  </div>
                  <div class="d-block d-sm-flex h-100 align-items-center">
                    
                    <div class="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
                      <h3 class="mb-0">
                        <i class="icon-arrow-up-circle"></i>{{ today | currency }}
                      </h3>
                      <p>Today</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-3 col-lg-6">
                <div class="p-20 border-lg-right border-bottom border-lg-bottom-0">
                  <div class="d-flex m-b-10">
                    <p class="mb-0 font-regular text-muted font-weight-bold">This Month's Order</p>
                    <a class="mb-0 ml-auto font-weight-bold">
                      <i class="ti ti-more-alt"></i>
                    </a>
                  </div>
                  <div class="d-block d-sm-flex h-100 align-items-center">
                   
                    <div class="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
                      <h3 class="mb-0">
                        <i class="icon-arrow-up-circle"></i>{{thisMonth | currency }}
                      </h3>
                      <p>This Month</p>
                    </div>
                  </div>
                </div>
              </div>

               <div class="col-xxl-3 col-lg-6">
                <div class="p-20 border-lg-right border-bottom border-lg-bottom-0">
                  <div class="d-flex m-b-10">
                    <p class="mb-0 font-regular text-muted font-weight-bold">This Year's Order</p>
                    <a class="mb-0 ml-auto font-weight-bold">
                      <i class="ti ti-more-alt"></i>
                    </a>
                  </div>
                  <div class="d-block d-sm-flex h-100 align-items-center">
                   
                    <div class="statistics mt-3 mt-sm-0 ml-sm-auto text-center text-sm-right">
                      <h3 class="mb-0">
                        <i class="icon-arrow-up-circle"></i>{{thisYear | currency }}
                      </h3>
                      <p>This Year</p>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>

</template>

<script>
export default {
    props: {
        thisYear: {
            type: Number,
            default: 0
        },
        thisMonth: {
            type: Number,
            default: 0
        },
        today: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ""
        }
    },
}
</script>