<template>
  <div class="app-main" id="main">
    <!-- begin container-fluid -->
    <div class="container-fluid">
      <!-- begin row -->
      <div class="row">
        <div class="col-md-12 m-b-30">
          <!-- begin page title -->
          <div class="d-block d-sm-flex flex-nowrap align-items-center">
            <div class="page-title mb-2 mb-sm-0">
              <h1>Dashboard</h1>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <nav>
                <ol class="breadcrumb p-0 m-b-0">
                  <li class="breadcrumb-item">
                    <a>
                      <i class="ti ti-home"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">Dashboard</li>
                </ol>
              </nav>
            </div>
          </div>
          <!-- end page title -->
        </div>
      </div>

      <DashboardCard  v-if="graph" title="Airtime" :today="graph.todayAirtime" :thisMonth="graph.thisMonthAirtime" :thisYear="graph.thisYearAirtime" />
       <DashboardCard  v-if="graph" title="Bank Transaction" :today="graph.todayBankTransaction" :thisMonth="graph.thisMonthBankTransaction" :thisYear="graph.thisYearBankTransaction" />
       <DashboardCard  v-if="graph" title="Cable Tv" :today="graph.todayCable" :thisMonth="graph.thisMonthCable" :thisYear="graph.thisYearCable" />
       <DashboardCard  v-if="graph" title="Data" :today="graph.todayData" :thisMonth="graph.thisMonthData" :thisYear="graph.thisYearData" />
       <DashboardCard  v-if="graph" title="Physician" :today="graph.todayPhysician" :thisMonth="graph.thisMonthPhysician" :thisYear="graph.thisYearPhysician" />
       <DashboardCard  v-if="graph" title="Power" :today="graph.todayPower" :thisMonth="graph.thisMonthPower" :thisYear="graph.thisYearPower" />
       <DashboardCard  v-if="graph" title="Wallet Transaction" :today="graph.todayWallet" :thisMonth="graph.thisMonthWallet" :thisYear="graph.thisYearWallet" />
       <DashboardCard  v-if="graph" title="Sme Transaction" :today="graph.todaySme" :thisMonth="graph.thisMonthSme" :thisYear="graph.thisYearSme" />
       
         <Loader v-else />

         <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Customer Signup Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <LineChart v-if="graph" :chartdata="chartdataUsers" :options="{  responsive: true, maintainAspectRatio: false,}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>
       
      </div>

      
      <!-- end row -->
      <!-- begin row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Overall Stats Overview</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <LineChart v-if="graph" :chartdata="chartdata" :options="options" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <!-- end row -->

         <div class="row">
        <div class="col-lg-4">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Airtime Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <PieChart v-if="graph" :chartdata="chartDataPieAirtime" :options="{}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Data Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <PieChart v-if="graph" :chartdata="chartDataPieData" :options="{}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>


         <div class="col-lg-4">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">CableTv Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <PieChart v-if="graph" :chartdata="chartDataPieCable" :options="{}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>
       

         <div class="col-lg-4">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Power Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <PieChart v-if="graph" :chartdata="chartDataPiePower" :options="{}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-statistics">
            <div class="card-header">
              <div class="card-heading">
                <h4 class="card-title">Sme Stats</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="chartjs-wrapper">
                <PieChart v-if="graph" :chartdata="chartDataPieSme" :options="{}" />
                  <Loader v-else />
              </div>
            </div>
          </div>
        </div>

        

         



      </div>
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<script>
import LineChart from '../components/LineChart';
import PieChart from '../components/PieChart';
import DashboardCard from '../components/DashboardCard';
import Swal from "sweetalert2";
export default {
  data() {
    return {
      graph: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
              ticks: {
                callback: function (value) {
                  return  new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'NGN' }).format(value);
                }
              }
            }]
        }
      }
    }
  },
  mounted() {
    this.getGraph();
  },
  computed: {
    chartdata() {
       return {
        labels: this.graph.airtimeTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Airtime Transaction',
            borderColor: 'red',
            data: this.graph.airtimeTransactionGraph.dataset_amount.data
          },
           {
            label: 'Bank Transfers',
            borderColor: 'blue',
            data: this.graph.bankTransactionGraph.dataset_amount.data
          },
           {
            label: 'Cable Tv Transaction',
            borderColor: 'green',
            data: this.graph.cableTransactionGraph.dataset_amount.data
          },
           {
            label: 'Data Transaction',
            borderColor: 'black',
            data: this.graph.dataTransactionGraph.dataset_amount.data
          },
           
           {
            label: 'Power Transaction',
            borderColor: '#f87f79',
            data: this.graph.powerTransactionGraph.dataset_amount.data
          },
            
            {
            label: 'Wallet Transaction',
            borderColor: 'pink',
            data: this.graph.walletTransactionGraph.dataset_amount.data
          },
          {
            label: 'Sme Transaction',
            borderColor: '#883322',
            data: this.graph.smeTransactionGraph.dataset_amount.data
          },
       
        ]
      };
    },

  chartdataUsers() {
       return {
        labels: this.graph.airtimeTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Customer Signup',
            borderColor: 'blue',
            data: this.graph.userStats.dataset_count.data
          },
        
       
        ]
      };
    },
    chartDataPieAirtime() {
      return {
        labels: this.graph.airtimeTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Airtime Transaction',
            backgroundColor: ['red','blue','green','pink','grey','black','indigo','yellow','purple','gold','white'],
            data: this.graph.airtimeTransactionGraph.dataset_amount.data
          },
          
       
        ]
      }

     
    },
    chartDataPieData() {
      return {
        labels: this.graph.dataTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Data Transaction',
            backgroundColor: ['red','blue','green','pink','grey','black','indigo','yellow','purple','gold','white'],
            data: this.graph.dataTransactionGraph.dataset_amount.data
          },
          
       
        ]
      }
    },

    chartDataPieCable() {
      return {
        labels: this.graph.cableTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Cable Transaction',
            backgroundColor: ['red','blue','green','pink','grey','black','indigo','yellow','purple','gold','white'],
            data: this.graph.cableTransactionGraph.dataset_amount.data
          },
          
       
        ]
      }
    },
     chartDataPiePower() {
      return {
        labels: this.graph.powerTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Power Transaction',
            backgroundColor: ['red','blue','green','pink','grey','black','indigo','yellow','purple','gold','white'],
            data: this.graph.powerTransactionGraph.dataset_amount.data
          },
          
       
        ]
      }
    },
    chartDataPieSme() {
      return {
        labels: this.graph.smeTransactionGraph.dataset_amount.labels,
        datasets: [
          {
            label: 'Sme Transaction',
            backgroundColor: ['red','blue','green','pink','grey','black','indigo','yellow','purple','gold','white'],
            data: this.graph.smeTransactionGraph.dataset_amount.data
          },
          
       
        ]
      }
    }
     
     
  },
  components:{
    LineChart,PieChart,DashboardCard
  },
  methods: {
     getGraph() {
      // this.graph = this.getters.dashboardData;
       fetch(
        this.$store.getters.baseURL + "dashboard/stats",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.getters.getToken
          }
        }
      )
        .then(res => res.json())
        .then(result => {
 
          if (result.status) {
            this.graph = result.data;
            // this.$store.commit("setDashboardData", result.data);
           
            // sessionStorage.setItem(
            //   "dashboardData",
            //   JSON.stringify(result.data)
            // );
          } else {
            Swal.fire({
              icon: "error",
              title: result.message
            });
          }
          this.$store.commit("setLoading", false);
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Check your connection"
          });
          this.$store.commit("setLoading", false);
        });
    }
  }
};
</script>

<style scoped>
</style>