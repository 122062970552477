import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Dashboard.vue'
import store from '../store'

Vue.use(VueRouter)

const isLoggedIn = () => {
    return store.getters.isLoggedIn;
}

const routes = [{
        path: '/',
        name: 'home',
        component: Home,
        beforeEnter: (to, from, next) => {
            if (isLoggedIn()) {
                next();
            } else {
                next({ name: "login" })

            }
            console.log(to);


            console.log(from);
        }
    },
    {
        path: '/roles',
        name: 'role',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Roles.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Register.vue')
    },
    {
        path: '/locked',
        name: 'locked',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Locked.vue')
    },
    {
        path: '/profile/:id',
        name: 'updateprofile',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/User.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Users.vue')
    },
    {
        path: '/app_settings',
        name: 'settings',
        component: () =>
            import ( /* webpackChunkName: "settings" */ '../views/Settings.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Chart.vue')
    },
    {
        path: '/airtime/purchase',
        name: 'airtimePurchase',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Airtime/Buy.vue')
    },
    {
        path: '/airtime_networks',
        name: 'airtimeNetworks',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Airtime/Networks.vue')
    },
    {
        path: '/airtime_transactions',
        name: 'airtimeTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Airtime/Transactions.vue')
    },
    {
        path: '/airtime_transactions/:id',
        name: 'userAirtimeTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Airtime/Transactions.vue')
    },
    {
        path: '/failed_airtime_transactions',
        name: 'failedAirtimeTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Airtime/FailedTransactions')
    },
    {
        path: '/data_networks',
        name: 'dataNetworks',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/Networks.vue')
    },
    {
        path: '/data/purchase',
        name: 'dataPurchase',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/Buy.vue')
    },
    {
        path: '/data_bundles/:id',
        name: 'dataBundles',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/NetworkBundles.vue')
    },
    {
        path: '/data_transactions',
        name: 'dataTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/Transactions.vue')
    },
    {
        path: '/data_transactions/:id',
        name: 'userDataTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/Transactions.vue')
    },
    {
        path: '/failed_data_transactions',
        name: 'failedDataTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Data/FailedTransactions')
    },
    {
        path: '/cable/purchase',
        name: 'cablePurchase',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/Buy.vue')
    },
    {
        path: '/bouquet_type',
        name: 'bouquetType',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/BouquetType.vue')
    },
    {
        path: '/bouquets/:typeId',
        name: 'bouquets',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/Bouquets.vue')
    },
    {
        path: '/cable_transanctions',
        name: 'cableTransactions',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/Transactions.vue')
    },
    {
        path: '/cable_transanctions/:id',
        name: 'cableTransactions',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/Transactions.vue')
    },
    {
        path: '/failed_cable_transactions',
        name: 'failedCableTransactions',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/CableTv/FailedTransactions.vue')
    },
    {
        path: '/voucher',
        name: 'voucher',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Voucher/Voucher.vue')
    },

    {
        path: '/power/purchase',
        name: 'buypower',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Power/Buy.vue')
    },
    {
        path: '/power/discos',
        name: 'disco',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Power/Discos.vue')
    },
    {
        path: '/power/transaction',
        name: 'transaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Power/Transactions.vue')
    },
    {
        path: '/power/transaction/:id',
        name: 'transaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Power/Transactions.vue')
    },
    {
        path: '/power/failed_transaction',
        name: 'failedTransaction',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Power/FailedTransactions.vue')
    },

    {
        path: '/restaurant',
        name: 'restaurant',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Restaurants/Restaurant.vue')
    },
    {
        path: '/restaurant/menu_items',
        name: 'menuItems',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Restaurants/MenuItems.vue')
    },
    {
        path: '/restaurant/routes',
        name: 'menuItems',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Restaurants/Routes.vue')
    },
    {
        path: '/restaurant/categories',
        name: 'menuItems',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Restaurants/Categories.vue')
    },
    {
        path: '/restaurant/orders',
        name: 'restaurantOrders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Restaurants/Orders.vue')
    },
    {
        path: '/store',
        name: 'store',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Stores/Store.vue')
    },
    {
        path: '/store/items',
        name: 'items',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Stores/Items.vue')
    },
    {
        path: '/store/routes',
        name: 'storeOrders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Stores/Routes.vue')
    },
    {
        path: '/store/orders',
        name: 'storeOrders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Stores/Orders.vue')
    },
    {
        path: '/store/categories',
        name: 'menuItems',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Stores/Categories.vue')
    },
    {
        path: '/dispatch/orders',
        name: 'dispatchOrders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Dispatch/Orders.vue')
    },
    {
        path: '/dispatch/routes',
        name: 'storeOrders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Dispatch/Routes.vue')
    },

    {
        path: '/services',
        name: 'services',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Services/Services.vue')
    },
    {
        path: '/services/artisans',
        name: 'artisans',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Services/Artisans.vue')
    },
    {
        path: '/services/requests',
        name: 'serviceRequest',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Services/Requests.vue')
    },

    {
        path: '/riders',
        name: 'riders',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Riders/Riders.vue')
    },
    {
        path: '/events',
        name: 'events',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Events/Events.vue')
    },
    {
        path: '/events/bookings',
        name: 'eventsBookings',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Events/Bookings.vue')
    },
    {
        path: '/banks',
        name: 'banks',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Bank/Bank.vue')
    },
    {
        path: '/banks/transactions',
        name: 'bankTransactions',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Bank/Transactions.vue')
    },
    {
        path: '/banks/failed_transactions',
        name: 'bankTransactions',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Bank/FailedTransactions.vue')
    },
    {
        path: '/cinemas',
        name: 'cinemas',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Movies/Cinemas.vue')
    },
    {
        path: '/movies',
        name: 'movies',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Movies/Movies.vue')
    },
    {
        path: '/movies/bookings',
        name: 'movieBookings',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Movies/Bookings.vue')
    },
    {
        path: '/goals',
        name: 'goals',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Goals/Goals.vue')
    },
   
    {
        path: '/states',
        name: 'states',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Locations/State.vue')
    },
    {
        path: '/locations',
        name: 'locations',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Locations/Locations.vue')
    },
    {
        path: '/wallet_wallet',
        name: 'walletToWallet',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Wallet/WalletWalletTransaction.vue')
    },
    {
        path: '/wallets_transaction',
        name: 'walletTransfer',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Wallet/WalletTransaction.vue')
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Task/Tasks.vue')
    },
    {
        path: '/tasks/subtask',
        name: 'subtask',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Task/SubTasks.vue')
    },
    {
        path: '/callback',
        name: 'callback',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Callback/Callback.vue')
    },
    {
        path: '/websites',
        name: 'websites',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Websites/Websites.vue')
    },
    {
        path: '/websites/settlements',
        name: 'websiteSettlements',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Websites/Settlements.vue')
    }

];

const router = new VueRouter({
    routes
})

export default router