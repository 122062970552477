import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {

        baseUrl: "https://services.suta.ng/api/v1/",
        permissions: JSON.parse(sessionStorage.getItem("permissions")),
        loggedIn: sessionStorage.getItem("isLoggedIn"),
        token: sessionStorage.getItem("token"),
        roles: JSON.parse(sessionStorage.getItem("roles")),
        user: JSON.parse(sessionStorage.getItem("user")),
        loading: false,
        dashboardData: JSON.parse(sessionStorage.getItem("dashboardData")),


    },
    mutations: {

        setPermissions(state, permissions) {
            state.permissions = permissions;
        },
        setToken(state, token) {
            state.token = token;
        },
        setLogin(state, status) {
            state.loggedIn = status;
        },

        setRoles(state, roles) {
            state.roles = roles;
        },

        setUser(state, user) {
            state.user = user;
        },

        setLoading(state, status) {
            state.loading = status;
        },
        setDashboardData(state, data) {
            state.dashboardData = data;
        }



    },
    actions: {

        logOut(context) {
            context.commit("setToken", "");
            context.commit("setPermissions", []);
            context.commit("setLogin", false);
            context.commit("setRoles", []);
            context.commit("setUser", {});
            sessionStorage.clear();
        },

    },
    modules: {},
    getters: {

        baseURL(state) {
            return state.baseUrl;
        },
        isLoggedIn(state) {
            return state.loggedIn;
        },
        getToken(state) {
            return state.token;
        },
        isLoading(state) {
            return state.loading;
        },
        getUser(state) {
            return state.user;
        },
        getDashboardData(state) {
            return state.dashboardData;
        }
        

    }
});