<template>
  <div class="app-wrap" v-if="hideBars">
    <Header />

    <!-- begin app-container -->
    <div class="app-container">
      <Sidebar />
      <router-view />
    </div>
    <!-- end app-container -->
    <Footer />
  </div>
  <router-view v-else />
  
</template>

<script>
import Header from "./components/Header";
import Sidebar from "./components/SideBar";
import Footer from "./components/Footer";


export default {
  components: {
    Header,
    Sidebar,
    Footer
  },
  mounted() {
    
  },
  computed: {
    hideBars () {
      if(this.$route.name == 'login' || this.$route.name == 'register' ) {
        return false;
      }
      return true;
    }
  }
};
</script>
<style>
@import "~vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
</style>
