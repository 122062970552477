import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import SlidingPagination from "vue-sliding-pagination";
import Loader from "./components/Loader";

Vue.component('sliding-pagination', SlidingPagination);
Vue.component('Loader', Loader);

Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('formatDate', function(value) {
    let date = new Date(value);
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
});


Vue.filter('currency', function(value) {

    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'NGN' }).format(value);

});


Vue.mixin({
    methods: {
        isNumber: function(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
        formatDate: function(value) {
            let date = new Date(value);
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        },
        currency: function(value) {
            return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'NGN' }).format(value);
        }
    },
})



Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')